/************
 * Developed by : Shiva Software Solutions
 * Date : 21-06-2021
 * Descriptions : Login AND Signup Functionality
 * Dependicies : To use JWT Authendication token
 ************/

import React from 'react';
import {
  FETCH_START,
  FETCH_SUCCESS,
  DIGI_L1TAG,
  FETCH_ERROR,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_CAMERA_PERMISSION,
  PERMISSIONS,
  OTP_SUCCESS,
  OTP_ERROR,
  FORGOT_PASWD_ERROR,
  FORGOT_PASWD_SUCCESS,
  LOGIN_ATTEMPT_ERROR,
  OTP_PASSWORD_ERROR,
  LOGIN_USER_FAILURE,
  WORD_PREVIEW_CLIENT,
  ACTIVE_SESSION_TAG,
  LOGIN_USER_SUCCESS,
  USER_PERMISSIONS,
  RELOAD_LOGIN,
  ONLINE_OFFLINE_STATUS,
  OTP_LOGIN_ERROR,
  OTP_LOGIN_SUCCESS,
  LOGOUT_ID,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  onWriteCloudWatchLog,
} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {userRole} from '../../shared/constants/AppConst';
import IntlMessages from '../../@crema/utility/IntlMessages';

// for encrypt and decrypt
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'JWTAuth.js';

export const onJwtUserSignUp = ({email, password, name}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, name, password};
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      dispatch(fetchError(err.response.data.error));
    }
  };
};

//Encrypt password
const encryptpassword = (pwd) => {
  try {
    var cipher = crypto.createCipher(algorithm, password);
    var crypted = cipher.update(pwd, 'utf8', 'hex');
    crypted += cipher.final('hex');
    return crypted;
  } catch (error) {
    throw new Error(error);
  }
};
console.log('enter');
// login crm portal with validation
export const onJwtSignIn = ({
  agentId,
  pwd,
  otp,
  sessionID,
  activateSession,
  remembermecookie,
}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    //pwd encryption
    var crypted = encryptpassword(pwd);
    var cryptedOtp = encryptpassword(otp);
    const body = {
      agentId,
      crypted,
      cryptedOtp,
      sessionID,
      activateSession,
      remembermecookie,
    };
    try {
      await jwtAxios
        .post('v1/userMgmt/loginjwt', body)
        .then((res) => {
          if (res.data !== '') {
            if (res.data.hasOwnProperty.call(res.data, 'message')) {
              if (res.data.message.includes('Error')) {
                if (res.data.message.includes('999')) {
                  dispatch({
                    type: LOGIN_USER_FAILURE,
                    payload: 'Agent ID or password is incorrect',
                  });
                } else if (res.data.message.includes('888')) {
                  dispatch({
                    type: LOGIN_USER_FAILURE,
                    payload: res.data.message.split(' ~ ')[1],
                  });
                } else if (res.data.message.includes('5')) {
                  // dispatch({
                  //   type: LOGIN_USER_FAILURE,
                  //   payload: 'User is locked',
                  // });
                  dispatch({
                    type: LOGIN_ATTEMPT_ERROR,
                    payload: res.data.message.split(' ~ ')[1],
                  });
                } else if (res.data.message.includes('6')) {
                  dispatch({
                    type: LOGIN_ATTEMPT_ERROR,
                    payload: res.data.message.split(' ~ ')[1],
                  });
                } else {
                  dispatch({
                    type: LOGIN_ATTEMPT_ERROR,
                    payload: '',
                  });
                }
              } else {
                dispatch({
                  type: LOGIN_USER_SUCCESS,
                  payload: res.data.message,
                });
              }
            } else {
              localStorage.setItem('token', res.data.body.token);
              if (!remembermecookie)
                document.getElementById('otptext').innerText = 'Get OTP';
              dispatch(setJWTToken(res.data.body.token));
              dispatch(loadJWTUser(body));
            }
          } else {
            dispatch({
              type: RELOAD_LOGIN,
              payload: true,
            });
            localStorage.clear();
            sessionStorage.clear();
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message.split(':')[1].trim(),
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJwtSignIn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onJwtSignIn', '0', err),
      );
    }
  };
};

const decryptvalue = (mobileno) => {
  try {
    var decipher = crypto.createDecipher(algorithm, password);
    var dec = decipher.update(mobileno, 'hex', 'utf8');
    dec += decipher.final('utf8');
    return dec;
  } catch (error) {
    throw new Error(error);
  }
};
// after login get the data from using token

export const loadJWTUser = (body) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      var token = localStorage.getItem('token');
      const session_id = localStorage.getItem('session_id');

      // const session_cookie=Cookies.get('session_id');
      // let session_data=!body.remembermecookie?session_id:session_cookie
      dispatch(fetchSuccess());
      body['jwtToken'] = token;

      await jwtAxios
        .post('v1/userMgmt/login', body)
        .then((res) => {
          dispatch({type: FETCH_SUCCESS});
          if (res.data !== '') {
            if (res.data.message.includes('10')) {
              dispatch({
                type: ACTIVE_SESSION_TAG,
                payload: true,
              });
            }
            if (res.data.body && res.data.body.data) {
              let sessionid = decryptvalue(res.data.body.data.sessionID);
              if (res.data.body.data.sessionID) {
                if (sessionid !== body.sessionID) {
                  dispatch({
                    type: LOGIN_USER_FAILURE,
                    payload: 'INVALID SESSION',
                  });
                  return;
                }
              } else {
                dispatch({
                  type: LOGIN_USER_FAILURE,
                  payload: 'INVALID SESSION',
                });
                return;
              }
            }
            var clientList = res?.data?.body?.generateWordClient;
            let locArr = [];
            if (clientList?.length > 0) {
              clientList.map((item) => {
                if (item?.clientID) {
                  let id = Number(item?.clientID);
                  locArr.push(id);
                }
              });
            }
            dispatch({
              type: WORD_PREVIEW_CLIENT,
              payload: locArr,
            });
            if (
              res.data.body &&
              res.data.body.data &&
              res.data.body.data.userlogin
            ) {
              var response = res.data.body.data.userlogin[0];
              let digiL2Tag = res?.data?.body?.data?.digitizationL2Tag
                ? res?.data?.body?.data?.digitizationL2Tag
                : false;
              var sessionId = res.data.body.data.sessionID;
              if (
                response.agentId.toLowerCase() !== body.agentId.toLowerCase()
              ) {
                return;
              }

              if (response.pwd !== body.crypted) {
                return;
              }
              var snoozeres = res.data.body.data.snooze
                ? res.data.body.data.snooze[0]
                : '';
              var permissions = res.data.body.data.permissionList;
              var roleName = userRole.user.find(
                (e) => e.roleId === response.roleId,
              );
              localStorage.setItem('roleId', response.roleId);
              localStorage.setItem('agentId', body.agentId);
              //pwd encryption ///cmd by venkat
              // var cipher = crypto.createCipher(algorithm, password);
              //var crypted = cipher.update(body.pwd, 'utf8', 'hex');
              // crypted += cipher.final('hex');
              var crypted = body.crypted;
              dispatch({
                type: RELOAD_LOGIN,
                payload: false,
              });
              var camPermission = res.data.body.data['cameraDashPermission'];
              dispatch({
                type: GET_CAMERA_PERMISSION,
                payload: camPermission,
              });

              dispatch({
                type: USER_PERMISSIONS,
                payload:
                  response.userPermissions &&
                  response.userPermissions.permission
                    ? response.userPermissions.permission
                    : {},
              });
              dispatch({
                type: UPDATE_AUTH_USER,
                payload: {
                  authType: AuthType.JWT_AUTH,
                  displayName: response.fName + ' ' + response.lName,
                  email: response.email,
                  userId:
                    response.userId === null || response.userId === undefined
                      ? ''
                      : response.userId,
                  agentId: response.agentId,
                  userCode: response.userCode,
                  roleId: response.roleId,
                  role: roleName.roleName,
                  statusCode: response.status,
                  token: token,
                  photoURL: '',
                  paswd: response.paswd,
                  userPermissions: response.userPermissions,
                  sessionID: sessionId,
                  userCategory: response.usercategory,
                  digitizationL2Tag: digiL2Tag,
                },
              });
              dispatch({
                type: DIGI_L1TAG,
                payload: digiL2Tag,
              });

              localStorage.setItem('agentpwd', crypted);
              localStorage.setItem('browserreload', false);

              if (snoozeres) {
                localStorage.setItem('snoozeTime', JSON.stringify(snoozeres));
              }

              dispatch({
                type: PERMISSIONS,
                payload: permissions,
              });
              dispatch({
                type: ONLINE_OFFLINE_STATUS,
                payload: '',
              });
            } else {
              if (res.data.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.message.includes('Error')) {
                  if (res.data.message.includes('999')) {
                    dispatch({
                      type: LOGIN_USER_FAILURE,
                      payload: 'Agent ID or password is incorrect',
                    });
                  } else if (res.data.message.includes('888')) {
                    dispatch({
                      type: LOGIN_USER_FAILURE,
                      payload: res.data.message.split(' ~ ')[1],
                    });
                  } else if (res.data.message.includes('5')) {
                    // dispatch({
                    //   type: LOGIN_USER_FAILURE,
                    //   payload: 'User is locked',
                    // });
                    dispatch({
                      type: LOGIN_ATTEMPT_ERROR,
                      payload: res.data.message.split(' ~ ')[1],
                    });
                  } else if (res.data.message.includes('6')) {
                    dispatch({
                      type: LOGIN_ATTEMPT_ERROR,
                      payload: res.data.message.split(' ~ ')[1],
                    });
                  } else if (res.data.message.includes('7')) {
                    dispatch({
                      type: LOGIN_USER_FAILURE,
                      payload: res.data.message.split(' ~ ')[1],
                    });
                  } else {
                    dispatch({
                      type: LOGIN_ATTEMPT_ERROR,
                      payload: '',
                    });
                  }
                } else {
                  dispatch({
                    type: LOGIN_USER_SUCCESS,
                    payload: res.data.message,
                  });
                }
              }
            }
          } else {
            dispatch({
              type: RELOAD_LOGIN,
              payload: true,
            });
            localStorage.clear();
            sessionStorage.clear();
          }
        })
        .catch((error) => {
          dispatch({type: UPDATE_AUTH_USER, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'loadJWTUser',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err?.response?.error));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'loadJWTUser', '0', err),
      );
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

// signout calling
export const onJWTAuthSignout = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/userMgmt/storeOnlineOfflineJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              value['jwtToken'] = data.data.body.token;
              jwtAxios
                .post('v1/userMgmt/storeOnlineOffline', value)
                .then((data) => {
                  if (data.status === 200) {
                    setTimeout(() => {
                      dispatch({
                        type: RELOAD_LOGIN,
                        payload: true,
                      });
                      localStorage.setItem('session_id', '');
                      dispatch({
                        type: UPDATE_AUTH_USER,
                        payload: null,
                      });
                      localStorage.clear();
                      sessionStorage.clear();
                      window.localStorage.clear();
                      dispatch({type: SIGNOUT_AUTH_SUCCESS});
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: LOGOUT_ID,
                        payload: true,
                      });
                    }, 500);
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onJWTAuthSignout',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onJWTAuthSignout',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJWTAuthSignout',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onJWTAuthSignout',
          '0',
          err,
        ),
      );
    }
  };
};

// forgot password otp api
export const onGetForgotpaswdOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/userMgmt/getForgotPasswordOtpGudmed', value)
        .then((res) => {
          if (res.hasOwnProperty.call(res.data, 'body')) {
            if (res.data.body === 'OTP SENT') {
              dispatch({
                type: OTP_ERROR,
                payload: '',
              });
              dispatch({
                type: OTP_SUCCESS,
                payload: true,
              });
            } else {
              dispatch({
                type: OTP_ERROR,
                payload: 'This Agent ID is not registered with us',
              });
            }
          } else {
            dispatch({
              type: OTP_ERROR,
              payload: 'This Agent ID is not registered with us',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error,
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetForgotpaswdOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetForgotpaswdOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};

//forgot password
export const onForgetPassword = ({agentid, newpaswd, otp}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      agentid,
      newpaswd,
      otp,
    };
    try {
      jwtAxios
        .post('v1/userMgmt/forgetpaswdjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              if (data.hasOwnProperty.call(data.data, 'body')) {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  agentid,
                  newpaswd,
                  otp,
                  jwtToken,
                };
                jwtAxios
                  .post('v1/userMgmt/forgetpaswd', bodytoken)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({
                        type: FORGOT_PASWD_SUCCESS,
                        payload: 'Your password has been changed successfully',
                      });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onForgetPassword',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                if (data.data.hasOwnProperty.call(data.data, 'message')) {
                  if (data.data.message.includes('Error')) {
                    if (data.data.message.includes('2')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('2')[1],
                      });
                    } else if (data.data.message.includes('1')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('1')[1],
                      });
                    } else if (data.data.message.includes('3')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('3')[1],
                      });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  }
                }
              }
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onForgetPassword',
                  '0',
                  err,
                ),
              );
            }
          } else {
            if (data.hasOwnProperty.call(data, 'message')) {
              if (data.message.includes('Error')) {
                if (data.message.includes('2')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('2')[1],
                  });
                } else if (data.message.includes('1')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('1')[1],
                  });
                } else if (data.data.message.includes('3')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.data.message.split('3')[1],
                  });
                } else {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: 'Invalid OTP',
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onForgetPassword',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onForgetPassword',
          '0',
          err,
        ),
      );
    }
  };
};

// Get gudmed OTP
export const onGetGudemedUserOtp = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/userMgmt/getForgotPasswordOtpGudmedOperators', value)
        .then((res) => {
          dispatch({type: FETCH_SUCCESS});
          if (res.hasOwnProperty.call(res.data, 'body')) {
            if (res.data.body === 'OTP SENT') {
              dispatch({
                type: OTP_LOGIN_ERROR,
                payload: '',
              });
              dispatch({
                type: OTP_LOGIN_SUCCESS,
                payload: true,
              });
            } else {
              dispatch({
                type: OTP_LOGIN_ERROR,
                payload: 'This Agent ID is not registered with us',
              });
            }
          } else {
            if (
              res.data.hasOwnProperty.call(res.data, 'message') &&
              res.data.message.includes('Error')
            ) {
              if (res.data.message.includes('5')) {
                dispatch({
                  type: OTP_PASSWORD_ERROR,
                  payload: res.data.message,
                });
              } else if (res.data.message.includes('6')) {
                dispatch({
                  type: ACTIVE_SESSION_TAG,
                  payload: true,
                });
              } else {
                dispatch({
                  type: OTP_LOGIN_ERROR,
                  payload: res.data.message.split(' ~ ')[1],
                });
              }
            } else {
              dispatch({
                type: OTP_ERROR,
                payload: 'This Agent ID is not registered with us',
              });
            }
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error,
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetGudemedUserOtp',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetGudemedUserOtp',
          '0',
          err,
        ),
      );
    }
  };
};

// signout calling
export const onJWTAuthFailed = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/userMgmt/authFailedJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              value['jwtToken'] = data.data.body.token;
              jwtAxios
                .post('v1/userMgmt/authFailed', value)
                .then((data) => {
                  if (data.status === 200) {
                    setTimeout(() => {
                      dispatch({
                        type: RELOAD_LOGIN,
                        payload: true,
                      });
                      localStorage.clear();
                      sessionStorage.clear();
                      dispatch({type: SIGNOUT_AUTH_SUCCESS});
                      dispatch({type: FETCH_SUCCESS});
                    }, 500);
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onJWTAuthFailed',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onJWTAuthFailed',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJWTAuthFailed',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onJWTAuthFailed', '0', err),
      );
    }
  };
};
